import React from 'react';
import Routes from './Routes/Routes';

const App = () => {
  return (
    <div>
      <Routes />
    </div>
  )
}

export default App
