export const home_text = {
  header: "UPGRADE YOUR GAME",
  text: "After years of playing football at a high level, it's now my job to ensure you get noticed!",
  button_text: "VIEW MORE"
};

export const about_text = {
  header: "A LITTLE BIT ABOUT ME",
  text: "Find out about my footballing career so far, and what led me to FTF.",
  button_text: "VIEW MORE"
};